export const countryListWithPhone = [
    {
     "id": 1,
     "name": "Afeganistão",
     "currency": "AFN",
     "phone": 93,
     "capital": "Kabul",
     "code": "AF",
     "code3": "AFG",
     "continent": "Ásia",
     "number": 4
    },
    {
     "id": 2,
     "name": "África do Sul",
     "currency": "ZAR",
     "phone": 27,
     "capital": "Pretoria",
     "code": "ZA",
     "code3": "ZAF",
     "continent": "África",
     "number": 710
    },
    {
     "id": 3,
     "name": "Albânia",
     "currency": "ALL",
     "phone": 355,
     "capital": "Tirana",
     "code": "AL",
     "code3": "ALB",
     "continent": "Europa",
     "number": 8
    },
    {
     "id": 4,
     "name": "Alemanha",
     "currency": "EUR",
     "phone": 49,
     "capital": "Berlin",
     "code": "DE",
     "code3": "DEU",
     "continent": "Europa",
     "number": 276
    },
    {
     "id": 5,
     "name": "Andorra",
     "currency": "EUR",
     "phone": 376,
     "capital": "Andorra la Vella",
     "code": "AD",
     "code3": "AND",
     "continent": "Europa",
     "number": 20
    },
    {
     "id": 6,
     "name": "Angola",
     "currency": "AOA",
     "phone": 244,
     "capital": "Luanda",
     "code": "AO",
     "code3": "AGO",
     "continent": "África",
     "number": 24
    },
    {
     "id": 7,
     "name": "Anguilla",
     "currency": "XCD",
     "phone": 1264,
     "capital": "The Valley",
     "code": "AI",
     "code3": "AIA",
     "continent": "América",
     "number": 660
    },
    {
     "id": 8,
     "name": "Antártida",
     "currency": "AAD",
     "phone": 672,
     "capital": "Antártida",
     "code": "AQ",
     "code3": "ATA",
     "continent": "Antártida",
     "number": 10
    },
    {
     "id": 9,
     "name": "Antígua e Barbuda",
     "currency": "XCD",
     "phone": 1268,
     "capital": "St. John's",
     "code": "AG",
     "code3": "ATG",
     "continent": "América",
     "number": 28
    },
    {
     "id": 11,
     "name": "Arábia Saudita",
     "currency": "SAR",
     "phone": 966,
     "capital": "Riyadh",
     "code": "SA",
     "code3": "SAU",
     "continent": "Ásia",
     "number": 682
    },
    {
     "id": 12,
     "name": "Argélia",
     "currency": "DZD",
     "phone": 213,
     "capital": "Algiers",
     "code": "DZ",
     "code3": "DZA",
     "continent": "África",
     "number": 12
    },
    {
     "id": 13,
     "name": "Argentina",
     "currency": "ARS",
     "phone": 54,
     "capital": "Buenos Aires",
     "code": "AR",
     "code3": "ARG",
     "continent": "América",
     "number": 32
    },
    {
     "id": 14,
     "name": "Armênia",
     "currency": "AMD",
     "phone": 374,
     "capital": "Yerevan",
     "code": "AM",
     "code3": "ARM",
     "continent": "Ásia",
     "number": 51
    },
    {
     "id": 15,
     "name": "Aruba",
     "currency": "AWG",
     "phone": 297,
     "capital": "Oranjestad",
     "code": "AW",
     "code3": "ABW",
     "continent": "América",
     "number": 533
    },
    {
     "id": 16,
     "name": "Austrália",
     "currency": "AUD",
     "phone": 61,
     "capital": "Canberra",
     "code": "AU",
     "code3": "AUS",
     "continent": "Oceania",
     "number": 36
    },
    {
     "id": 17,
     "name": "Áustria",
     "currency": "EUR",
     "phone": 43,
     "capital": "Vienna",
     "code": "AT",
     "code3": "AUT",
     "continent": "Europa",
     "number": 40
    },
    {
     "id": 18,
     "name": "Azerbaijão",
     "currency": "AZN",
     "phone": 994,
     "capital": "Baku",
     "code": "AZ",
     "code3": "AZE",
     "continent": "Ásia",
     "number": 31
    },
    {
     "id": 19,
     "name": "Bahamas",
     "currency": "BSD",
     "phone": 1242,
     "capital": "Nassau",
     "code": "BS",
     "code3": "BHS",
     "continent": "América",
     "number": 44
    },
    {
     "id": 20,
     "name": "Bahrein",
     "currency": "BHD",
     "phone": 973,
     "capital": "Manama",
     "code": "BH",
     "code3": "BHR",
     "continent": "Ásia",
     "number": 48
    },
    {
     "id": 21,
     "name": "Bangladesh",
     "currency": "BDT",
     "phone": 880,
     "capital": "Dhaka",
     "code": "BD",
     "code3": "BGD",
     "continent": "Ásia",
     "number": 50
    },
    {
     "id": 22,
     "name": "Barbados",
     "currency": "BBD",
     "phone": 1246,
     "capital": "Bridgetown",
     "code": "BB",
     "code3": "BRB",
     "continent": "América",
     "number": 52
    },
    {
     "id": 23,
     "name": "Bélgica",
     "currency": "EUR",
     "phone": 32,
     "capital": "Brussels",
     "code": "BE",
     "code3": "BEL",
     "continent": "Europa",
     "number": 56
    },
    {
     "id": 24,
     "name": "Belize",
     "currency": "BZD",
     "phone": 501,
     "capital": "Belmopan",
     "code": "BZ",
     "code3": "BLZ",
     "continent": "América",
     "number": 84
    },
    {
     "id": 25,
     "name": "Benin",
     "currency": "XOF",
     "phone": 229,
     "capital": "Porto-Novo",
     "code": "BJ",
     "code3": "BEN",
     "continent": "África",
     "number": 204
    },
    {
     "id": 26,
     "name": "Bermudas",
     "currency": "BMD",
     "phone": 1441,
     "capital": "Hamilton",
     "code": "BM",
     "code3": "BMU",
     "continent": "América",
     "number": 60
    },
    {
     "id": 27,
     "name": "Bielo-Rússia",
     "currency": "BYN",
     "phone": 375,
     "capital": "Minsk",
     "code": "BY",
     "code3": "BLR",
     "continent": "Europa",
     "number": 112
    },
    {
     "id": 28,
     "name": "Bolívia",
     "currency": "BOB",
     "phone": 591,
     "capital": "Sucre",
     "code": "BO",
     "code3": "BOL",
     "continent": "América",
     "number": 68
    },
    {
     "id": 29,
     "name": "Bósnia e Herzegovina",
     "currency": "BAM",
     "phone": 387,
     "capital": "Sarajevo",
     "code": "BA",
     "code3": "BIH",
     "continent": "Europa",
     "number": 70
    },
    {
     "id": 30,
     "name": "Botswana",
     "currency": "BWP",
     "phone": 267,
     "capital": "Gaborone",
     "code": "BW",
     "code3": "BWA",
     "continent": "África",
     "number": 72
    },
    {
     "id": 31,
     "name": "Brasil",
     "currency": "BRL",
     "phone": 55,
     "capital": "Brasilia",
     "code": "BR",
     "code3": "BRA",
     "continent": "América",
     "number": 76
    },
    {
     "id": 32,
     "name": "Brunei",
     "currency": "BND",
     "phone": 673,
     "capital": "Bandar Seri Begawan",
     "code": "BN",
     "code3": "BRN",
     "continent": "Ásia",
     "number": 96
    },
    {
     "id": 33,
     "name": "Bulgária",
     "currency": "BGN",
     "phone": 359,
     "capital": "Sofia",
     "code": "BG",
     "code3": "BGR",
     "continent": "Europa",
     "number": 100
    },
    {
     "id": 34,
     "name": "Burkina Faso",
     "currency": "XOF",
     "phone": 226,
     "capital": "Ouagadougou",
     "code": "BF",
     "code3": "BFA",
     "continent": "África",
     "number": 854
    },
    {
     "id": 35,
     "name": "Burundi",
     "currency": "BIF",
     "phone": 257,
     "capital": "Bujumbura",
     "code": "BI",
     "code3": "BDI",
     "continent": "África",
     "number": 108
    },
    {
     "id": 36,
     "name": "Butão",
     "currency": "BTN",
     "phone": 975,
     "capital": "Thimphu",
     "code": "BT",
     "code3": "BTN",
     "continent": "Ásia",
     "number": 64
    },
    {
     "id": 37,
     "name": "Cabo Verde",
     "currency": "CVE",
     "phone": 238,
     "capital": "Praia",
     "code": "CV",
     "code3": "CPV",
     "continent": "África",
     "number": 132
    },
    {
     "id": 38,
     "name": "Camarões",
     "currency": "XAF",
     "phone": 237,
     "capital": "Yaounde",
     "code": "CM",
     "code3": "CMR",
     "continent": "África",
     "number": 120
    },
    {
     "id": 39,
     "name": "Camboja",
     "currency": "KHR",
     "phone": 855,
     "capital": "Phnom Penh",
     "code": "KH",
     "code3": "KHM",
     "continent": "Ásia",
     "number": 116
    },
    {
     "id": 40,
     "name": "Canadá",
     "currency": "CAD",
     "phone": 1,
     "capital": "Ottawa",
     "code": "CA",
     "code3": "CAN",
     "continent": "América",
     "number": 124
    },
    {
     "id": 41,
     "name": "Catar",
     "currency": "QAR",
     "phone": 974,
     "capital": "Doha",
     "code": "QA",
     "code3": "QAT",
     "continent": "Ásia",
     "number": 634
    },
    {
     "id": 42,
     "name": "Cazaquistão",
     "currency": "KZT",
     "phone": 7,
     "capital": "Astana",
     "code": "KZ",
     "code3": "KAZ",
     "continent": "Ásia",
     "number": 398
    },
    {
     "id": 43,
     "name": "Chade",
     "currency": "XAF",
     "phone": 235,
     "capital": "N'Djamena",
     "code": "TD",
     "code3": "TCD",
     "continent": "África",
     "number": 148
    },
    {
     "id": 44,
     "name": "Chile",
     "currency": "CLP",
     "phone": 56,
     "capital": "Santiago",
     "code": "CL",
     "code3": "CHL",
     "continent": "América",
     "number": 152
    },
    {
     "id": 45,
     "name": "China",
     "currency": "CNY",
     "phone": 86,
     "capital": "Beijing",
     "code": "CN",
     "code3": "CHN",
     "continent": "Ásia",
     "number": 156
    },
    {
     "id": 46,
     "name": "Chipre",
     "currency": "EUR",
     "phone": 357,
     "capital": "Nicosia",
     "code": "CY",
     "code3": "CYP",
     "continent": "Ásia",
     "number": 196
    },
    {
     "id": 47,
     "name": "Cingapura",
     "currency": "SGD",
     "phone": 65,
     "capital": "Singapur",
     "code": "SG",
     "code3": "SGP",
     "continent": "Ásia",
     "number": 702
    },
    {
     "id": 48,
     "name": "Colômbia",
     "currency": "COP",
     "phone": 57,
     "capital": "Bogota",
     "code": "CO",
     "code3": "COL",
     "continent": "América",
     "number": 170
    },
    {
     "id": 49,
     "name": "Comores",
     "currency": "KMF",
     "phone": 269,
     "capital": "Moroni",
     "code": "KM",
     "code3": "COM",
     "continent": "África",
     "number": 174
    },
    {
     "id": 50,
     "name": "Congo",
     "currency": "XAF",
     "phone": 242,
     "capital": "Brazzaville",
     "code": "CG",
     "code3": "COG",
     "continent": "África",
     "number": 178
    },
    {
     "id": 51,
     "name": "Coréia do Norte",
     "currency": "KPW",
     "phone": 850,
     "capital": "Pyongyang",
     "code": "KP",
     "code3": "PRK",
     "continent": "Ásia",
     "number": 408
    },
    {
     "id": 52,
     "name": "Coréia do Sul",
     "currency": "KRW",
     "phone": 82,
     "capital": "Seoul",
     "code": "KR",
     "code3": "KOR",
     "continent": "Ásia",
     "number": 410
    },
    {
     "id": 53,
     "name": "Costa do Marfim",
     "currency": "XOF",
     "phone": 225,
     "capital": "Yamoussoukro",
     "code": "CI",
     "code3": "CIV",
     "continent": "África",
     "number": 384
    },
    {
     "id": 54,
     "name": "Costa Rica",
     "currency": "CRC",
     "phone": 506,
     "capital": "San Jose",
     "code": "CR",
     "code3": "CRI",
     "continent": "América",
     "number": 188
    },
    {
     "id": 55,
     "name": "Croácia",
     "currency": "HRK",
     "phone": 385,
     "capital": "Zagreb",
     "code": "HR",
     "code3": "HRV",
     "continent": "Europa",
     "number": 191
    },
    {
     "id": 56,
     "name": "Cuba",
     "currency": "CUP",
     "phone": 53,
     "capital": "Havana",
     "code": "CU",
     "code3": "CUB",
     "continent": "América",
     "number": 192
    },
    {
     "id": 57,
     "name": "Curaçao",
     "currency": "ANG",
     "phone": 599,
     "capital": "Willemstad",
     "code": "CW",
     "code3": "CUW",
     "continent": "América",
     "number": 531
    },
    {
     "id": 58,
     "name": "Dinamarca",
     "currency": "DKK",
     "phone": 45,
     "capital": "Copenhagen",
     "code": "DK",
     "code3": "DNK",
     "continent": "Europa",
     "number": 208
    },
    {
     "id": 59,
     "name": "Djibouti",
     "currency": "DJF",
     "phone": 253,
     "capital": "Djibouti",
     "code": "DJ",
     "code3": "DJI",
     "continent": "África",
     "number": 262
    },
    {
     "id": 60,
     "name": "Dominica",
     "currency": "XCD",
     "phone": 1767,
     "capital": "Roseau",
     "code": "DM",
     "code3": "DMA",
     "continent": "América",
     "number": 212
    },
    {
     "id": 61,
     "name": "Egito",
     "currency": "EGP",
     "phone": 20,
     "capital": "Cairo",
     "code": "EG",
     "code3": "EGY",
     "continent": "África",
     "number": 818
    },
    {
     "id": 62,
     "name": "El Salvador",
     "currency": "USD",
     "phone": 503,
     "capital": "San Salvador",
     "code": "SV",
     "code3": "SLV",
     "continent": "América",
     "number": 222
    },
    {
     "id": 63,
     "name": "Emirados Árabes Unidos",
     "currency": "AED",
     "phone": 971,
     "capital": "Abu Dhabi",
     "code": "AE",
     "code3": "ARE",
     "continent": "Ásia",
     "number": 784
    },
    {
     "id": 64,
     "name": "Equador",
     "currency": "USD",
     "phone": 593,
     "capital": "Quito",
     "code": "EC",
     "code3": "ECU",
     "continent": "América",
     "number": 218
    },
    {
     "id": 65,
     "name": "Eritreia",
     "currency": "ERN",
     "phone": 291,
     "capital": "Asmara",
     "code": "ER",
     "code3": "ERI",
     "continent": "África",
     "number": 232
    },
    {
     "id": 66,
     "name": "Eslováquia",
     "currency": "EUR",
     "phone": 421,
     "capital": "Bratislava",
     "code": "SK",
     "code3": "SVK",
     "continent": "Europa",
     "number": 703
    },
    {
     "id": 67,
     "name": "Eslovênia",
     "currency": "EUR",
     "phone": 386,
     "capital": "Ljubljana",
     "code": "SI",
     "code3": "SVN",
     "continent": "Europa",
     "number": 705
    },
    {
     "id": 68,
     "name": "Espanha",
     "currency": "EUR",
     "phone": 34,
     "capital": "Madrid",
     "code": "ES",
     "code3": "ESP",
     "continent": "Europa",
     "number": 724
    },
    {
     "id": 69,
     "name": "Estados Unidos",
     "currency": "USD",
     "phone": 1,
     "capital": "Washington",
     "code": "US",
     "code3": "USA",
     "continent": "América",
     "number": 840
    },
    {
     "id": 70,
     "name": "Estônia",
     "currency": "EUR",
     "phone": 372,
     "capital": "Tallinn",
     "code": "EE",
     "code3": "EST",
     "continent": "Europa",
     "number": 233
    },
    {
     "id": 71,
     "name": "Etiópia",
     "currency": "ETB",
     "phone": 251,
     "capital": "Addis Ababa",
     "code": "ET",
     "code3": "ETH",
     "continent": "África",
     "number": 231
    },
    {
     "id": 72,
     "name": "Fiji",
     "currency": "FJD",
     "phone": 679,
     "capital": "Suva",
     "code": "FJ",
     "code3": "FJI",
     "continent": "Oceania",
     "number": 242
    },
    {
     "id": 73,
     "name": "Filipinas",
     "currency": "PHP",
     "phone": 63,
     "capital": "Manila",
     "code": "PH",
     "code3": "PHL",
     "continent": "Ásia",
     "number": 608
    },
    {
     "id": 74,
     "name": "Finlândia",
     "currency": "EUR",
     "phone": 358,
     "capital": "Helsinki",
     "code": "FI",
     "code3": "FIN",
     "continent": "Europa",
     "number": 246
    },
    {
     "id": 75,
     "name": "França",
     "currency": "EUR",
     "phone": 33,
     "capital": "Paris",
     "code": "FR",
     "code3": "FRA",
     "continent": "Europa",
     "number": 250
    },
    {
     "id": 76,
     "name": "Gabão",
     "currency": "XAF",
     "phone": 241,
     "capital": "Libreville",
     "code": "GA",
     "code3": "GAB",
     "continent": "África",
     "number": 266
    },
    {
     "id": 77,
     "name": "Gâmbia",
     "currency": "GMD",
     "phone": 220,
     "capital": "Banjul",
     "code": "GM",
     "code3": "GMB",
     "continent": "África",
     "number": 270
    },
    {
     "id": 78,
     "name": "Gana",
     "currency": "GHS",
     "phone": 233,
     "capital": "Accra",
     "code": "GH",
     "code3": "GHA",
     "continent": "África",
     "number": 288
    },
    {
     "id": 79,
     "name": "Geórgia",
     "currency": "GEL",
     "phone": 995,
     "capital": "Tbilisi",
     "code": "GE",
     "code3": "GEO",
     "continent": "Ásia",
     "number": 268
    },
    {
     "id": 80,
     "name": "Gibraltar",
     "currency": "GIP",
     "phone": 350,
     "capital": "Gibraltar",
     "code": "GI",
     "code3": "GIB",
     "continent": "Europa",
     "number": 292
    },
    {
     "id": 81,
     "name": "Granada",
     "currency": "XCD",
     "phone": 1473,
     "capital": "St. George's",
     "code": "GD",
     "code3": "GRD",
     "continent": "América",
     "number": 308
    },
    {
     "id": 82,
     "name": "Grécia",
     "currency": "EUR",
     "phone": 30,
     "capital": "Athens",
     "code": "GR",
     "code3": "GRC",
     "continent": "Europa",
     "number": 300
    },
    {
     "id": 83,
     "name": "Groenlândia",
     "currency": "DKK",
     "phone": 299,
     "capital": "Nuuk",
     "code": "GL",
     "code3": "GRL",
     "continent": "América",
     "number": 304
    },
    {
     "id": 84,
     "name": "Guadalupe",
     "currency": "EUR",
     "phone": 590,
     "capital": "Basse-Terre",
     "code": "GP",
     "code3": "GLP",
     "continent": "América",
     "number": 312
    },
    {
     "id": 85,
     "name": "Guam",
     "currency": "USD",
     "phone": 1671,
     "capital": "Hagatna",
     "code": "GU",
     "code3": "GUM",
     "continent": "Oceania",
     "number": 316
    },
    {
     "id": 86,
     "name": "Guatemala",
     "currency": "GTQ",
     "phone": 502,
     "capital": "Guatemala City",
     "code": "GT",
     "code3": "GTM",
     "continent": "América",
     "number": 320
    },
    {
     "id": 87,
     "name": "Guernsey",
     "currency": "GBP",
     "phone": 44,
     "capital": "St Peter Port",
     "code": "GG",
     "code3": "GGY",
     "continent": "Europa",
     "number": 831
    },
    {
     "id": 88,
     "name": "Guiana",
     "currency": "GYD",
     "phone": 592,
     "capital": "Georgetown",
     "code": "GY",
     "code3": "GUY",
     "continent": "América",
     "number": 328
    },
    {
     "id": 89,
     "name": "Guiana Francesa",
     "currency": "EUR",
     "phone": 594,
     "capital": "Cayenne",
     "code": "GF",
     "code3": "GUF",
     "continent": "América",
     "number": 254
    },
    {
     "id": 90,
     "name": "Guiné",
     "currency": "GNF",
     "phone": 224,
     "capital": "Conakry",
     "code": "GN",
     "code3": "GIN",
     "continent": "África",
     "number": 324
    },
    {
     "id": 91,
     "name": "Guiné Equatorial",
     "currency": "XAF",
     "phone": 240,
     "capital": "Malabo",
     "code": "GQ",
     "code3": "GNQ",
     "continent": "África",
     "number": 226
    },
    {
     "id": 92,
     "name": "Guiné-Bissau",
     "currency": "XOF",
     "phone": 245,
     "capital": "Bissau",
     "code": "GW",
     "code3": "GNB",
     "continent": "África",
     "number": 624
    },
    {
     "id": 93,
     "name": "Haiti",
     "currency": "HTG",
     "phone": 509,
     "capital": "Port-au-Prince",
     "code": "HT",
     "code3": "HTI",
     "continent": "América",
     "number": 332
    },
    {
     "id": 94,
     "name": "Holanda",
     "currency": "EUR",
     "phone": 31,
     "capital": "Amsterdam",
     "code": "NL",
     "code3": "NLD",
     "continent": "Europa",
     "number": 528
    },
    {
     "id": 95,
     "name": "Honduras",
     "currency": "HNL",
     "phone": 504,
     "capital": "Tegucigalpa",
     "code": "HN",
     "code3": "HND",
     "continent": "América",
     "number": 340
    },
    {
     "id": 96,
     "name": "Hong Kong",
     "currency": "HKD",
     "phone": 852,
     "capital": "Hong Kong",
     "code": "HK",
     "code3": "HKG",
     "continent": "Ásia",
     "number": 344
    },
    {
     "id": 97,
     "name": "Hungria",
     "currency": "HUF",
     "phone": 36,
     "capital": "Budapest",
     "code": "HU",
     "code3": "HUN",
     "continent": "Europa",
     "number": 348
    },
    {
     "id": 98,
     "name": "Iêmen",
     "currency": "YER",
     "phone": 967,
     "capital": "Sanaa",
     "code": "YE",
     "code3": "YEM",
     "continent": "Ásia",
     "number": 887
    },
    {
     "id": 99,
     "name": "Ilha Bouvet",
     "currency": "NOK",
     "phone": 55,
     "capital": null,
     "code": "BV",
     "code3": "BVT",
     "continent": "Antártida",
     "number": 74
    },
    {
     "id": 100,
     "name": "Ilha da Reunião",
     "currency": "EUR",
     "phone": 262,
     "capital": "Saint-Denis",
     "code": "RE",
     "code3": "REU",
     "continent": "África",
     "number": 638
    },
    {
     "id": 101,
     "name": "Ilha de Man",
     "currency": "GBP",
     "phone": 44,
     "capital": "Douglas, Isle of Man",
     "code": "IM",
     "code3": "IMN",
     "continent": "Europa",
     "number": 833
    },
    {
     "id": 102,
     "name": "Ilha de São Martinho(Francês)",
     "currency": "EUR",
     "phone": 590,
     "capital": "Marigot",
     "code": "MF",
     "code3": "MAF",
     "continent": "América",
     "number": 663
    },
    {
     "id": 103,
     "name": "Ilha Heard e Ilhas McDonald",
     "currency": "AUD",
     "phone": 0,
     "capital": null,
     "code": "HM",
     "code3": "HMD",
     "continent": "Antártida",
     "number": 334
    },
    {
     "id": 104,
     "name": "Ilha Natal",
     "currency": "AUD",
     "phone": 61,
     "capital": "Flying Fish Cove",
     "code": "CX",
     "code3": "CXR",
     "continent": "Ásia",
     "number": 162
    },
    {
     "id": 105,
     "name": "Ilha Norfolk",
     "currency": "AUD",
     "phone": 672,
     "capital": "Kingston",
     "code": "NF",
     "code3": "NFK",
     "continent": "Oceania",
     "number": 574
    },
    {
     "id": 106,
     "name": "Ilhas Aland",
     "currency": "EUR",
     "phone": 358,
     "capital": "Mariehamn",
     "code": "AX",
     "code3": "ALA",
     "continent": "Europa",
     "number": 248
    },
    {
     "id": 107,
     "name": "Ilhas Cayman",
     "currency": "KYD",
     "phone": 1345,
     "capital": "George Town",
     "code": "KY",
     "code3": "CYM",
     "continent": "América",
     "number": 136
    },
    {
     "id": 108,
     "name": "Ilhas Cocos (Keeling)",
     "currency": "AUD",
     "phone": 672,
     "capital": "West Island",
     "code": "CC",
     "code3": "CCK",
     "continent": "Ásia",
     "number": 166
    },
    {
     "id": 109,
     "name": "Ilhas Cook",
     "currency": "NZD",
     "phone": 682,
     "capital": "Avarua",
     "code": "CK",
     "code3": "COK",
     "continent": "Oceania",
     "number": 184
    },
    {
     "id": 110,
     "name": "Ilhas Falkland (Malvinas)",
     "currency": "FKP",
     "phone": 500,
     "capital": "Stanley",
     "code": "FK",
     "code3": "FLK",
     "continent": "América",
     "number": 238
    },
    {
     "id": 111,
     "name": "ilhas Faroe",
     "currency": "DKK",
     "phone": 298,
     "capital": "Torshavn",
     "code": "FO",
     "code3": "FRO",
     "continent": "Europa",
     "number": 234
    },
    {
     "id": 112,
     "name": "Ilhas Geórgia do Sul e Sandwich do Sul",
     "currency": "GBP",
     "phone": 500,
     "capital": "Grytviken",
     "code": "GS",
     "code3": "SGS",
     "continent": "Antártida",
     "number": 239
    },
    {
     "id": 113,
     "name": "Ilhas Marianas do Norte",
     "currency": "USD",
     "phone": 1670,
     "capital": "Saipan",
     "code": "MP",
     "code3": "MNP",
     "continent": "Oceania",
     "number": 580
    },
    {
     "id": 114,
     "name": "Ilhas Marshall",
     "currency": "USD",
     "phone": 692,
     "capital": "Majuro",
     "code": "MH",
     "code3": "MHL",
     "continent": "Oceania",
     "number": 584
    },
    {
     "id": 115,
     "name": "Ilhas Menores Distantes dos Estados Unidos",
     "currency": "USD",
     "phone": 246,
     "capital": null,
     "code": "UM",
     "code3": "UMI",
     "continent": "América",
     "number": 581
    },
    {
     "id": 116,
     "name": "Ilhas Pitcairn",
     "currency": "NZD",
     "phone": 64,
     "capital": "Adamstown",
     "code": "PN",
     "code3": "PCN",
     "continent": "Oceania",
     "number": 612
    },
    {
     "id": 117,
     "name": "Ilhas Salomão",
     "currency": "SBD",
     "phone": 677,
     "capital": "Honiara",
     "code": "SB",
     "code3": "SLB",
     "continent": "Oceania",
     "number": 90
    },
    {
     "id": 118,
     "name": "Ilhas Turcas e Caicos",
     "currency": "USD",
     "phone": 1649,
     "capital": "Cockburn Town",
     "code": "TC",
     "code3": "TCA",
     "continent": "América",
     "number": 796
    },
    {
     "id": 119,
     "name": "Ilhas Virgens Americanas",
     "currency": "USD",
     "phone": 1340,
     "capital": "Charlotte Amalie",
     "code": "VI",
     "code3": "VIR",
     "continent": "América",
     "number": 850
    },
    {
     "id": 120,
     "name": "Ilhas Virgens Britânicas",
     "currency": "USD",
     "phone": 1284,
     "capital": "Road Town",
     "code": "VG",
     "code3": "VGB",
     "continent": "América",
     "number": 92
    },
    {
     "id": 121,
     "name": "Índia",
     "currency": "INR",
     "phone": 91,
     "capital": "New Delhi",
     "code": "IN",
     "code3": "IND",
     "continent": "Ásia",
     "number": 356
    },
    {
     "id": 122,
     "name": "Indonésia",
     "currency": "IDR",
     "phone": 62,
     "capital": "Jakarta",
     "code": "ID",
     "code3": "IDN",
     "continent": "Ásia",
     "number": 360
    },
    {
     "id": 123,
     "name": "Irã",
     "currency": "IRR",
     "phone": 98,
     "capital": "Tehran",
     "code": "IR",
     "code3": "IRN",
     "continent": "Ásia",
     "number": 364
    },
    {
     "id": 124,
     "name": "Iraque",
     "currency": "IQD",
     "phone": 964,
     "capital": "Baghdad",
     "code": "IQ",
     "code3": "IRQ",
     "continent": "Ásia",
     "number": 368
    },
    {
     "id": 125,
     "name": "Irlanda",
     "currency": "EUR",
     "phone": 353,
     "capital": "Dublin",
     "code": "IE",
     "code3": "IRL",
     "continent": "Europa",
     "number": 372
    },
    {
     "id": 126,
     "name": "Islândia",
     "currency": "ISK",
     "phone": 354,
     "capital": "Reykjavik",
     "code": "IS",
     "code3": "ISL",
     "continent": "Europa",
     "number": 352
    },
    {
     "id": 127,
     "name": "Israel",
     "currency": "ILS",
     "phone": 972,
     "capital": "Jerusalem",
     "code": "IL",
     "code3": "ISR",
     "continent": "Ásia",
     "number": 376
    },
    {
     "id": 128,
     "name": "Itália",
     "currency": "EUR",
     "phone": 39,
     "capital": "Rome",
     "code": "IT",
     "code3": "ITA",
     "continent": "Europa",
     "number": 380
    },
    {
     "id": 129,
     "name": "Jamaica",
     "currency": "JMD",
     "phone": 1876,
     "capital": "Kingston",
     "code": "JM",
     "code3": "JAM",
     "continent": "América",
     "number": 388
    },
    {
     "id": 130,
     "name": "Japão",
     "currency": "JPY",
     "phone": 81,
     "capital": "Tokyo",
     "code": "JP",
     "code3": "JPN",
     "continent": "Ásia",
     "number": 392
    },
    {
     "id": 131,
     "name": "Jersey",
     "currency": "GBP",
     "phone": 44,
     "capital": "Saint Helier",
     "code": "JE",
     "code3": "JEY",
     "continent": "Europa",
     "number": 832
    },
    {
     "id": 132,
     "name": "Jordânia",
     "currency": "JOD",
     "phone": 962,
     "capital": "Amman",
     "code": "JO",
     "code3": "JOR",
     "continent": "Ásia",
     "number": 400
    },
    {
     "id": 133,
     "name": "Kiribati",
     "currency": "AUD",
     "phone": 686,
     "capital": "Tarawa",
     "code": "KI",
     "code3": "KIR",
     "continent": "Oceania",
     "number": 296
    },
    {
     "id": 134,
     "name": "Kosovo",
     "currency": "EUR",
     "phone": 383,
     "capital": "Pristina",
     "code": "XK",
     "code3": "XKX",
     "continent": "Europa",
     "number": 926
    },
    {
     "id": 135,
     "name": "Kuwait",
     "currency": "KWD",
     "phone": 965,
     "capital": "Kuwait City",
     "code": "KW",
     "code3": "KWT",
     "continent": "Ásia",
     "number": 414
    },
    {
     "id": 136,
     "name": "Laos",
     "currency": "LAK",
     "phone": 856,
     "capital": "Vientiane",
     "code": "LA",
     "code3": "LAO",
     "continent": "Ásia",
     "number": 418
    },
    {
     "id": 137,
     "name": "Lesoto",
     "currency": "LSL",
     "phone": 266,
     "capital": "Maseru",
     "code": "LS",
     "code3": "LSO",
     "continent": "África",
     "number": 426
    },
    {
     "id": 138,
     "name": "Letônia",
     "currency": "EUR",
     "phone": 371,
     "capital": "Riga",
     "code": "LV",
     "code3": "LVA",
     "continent": "Europa",
     "number": 428
    },
    {
     "id": 139,
     "name": "Líbano",
     "currency": "LBP",
     "phone": 961,
     "capital": "Beirut",
     "code": "LB",
     "code3": "LBN",
     "continent": "Ásia",
     "number": 422
    },
    {
     "id": 140,
     "name": "Libéria",
     "currency": "LRD",
     "phone": 231,
     "capital": "Monrovia",
     "code": "LR",
     "code3": "LBR",
     "continent": "África",
     "number": 430
    },
    {
     "id": 141,
     "name": "Líbia",
     "currency": "LYD",
     "phone": 218,
     "capital": "Tripolis",
     "code": "LY",
     "code3": "LBY",
     "continent": "África",
     "number": 434
    },
    {
     "id": 142,
     "name": "Liechtenstein",
     "currency": "CHF",
     "phone": 423,
     "capital": "Vaduz",
     "code": "LI",
     "code3": "LIE",
     "continent": "Europa",
     "number": 438
    },
    {
     "id": 143,
     "name": "Lituânia",
     "currency": "EUR",
     "phone": 370,
     "capital": "Vilnius",
     "code": "LT",
     "code3": "LTU",
     "continent": "Europa",
     "number": 440
    },
    {
     "id": 144,
     "name": "Luxemburgo",
     "currency": "EUR",
     "phone": 352,
     "capital": "Luxembourg",
     "code": "LU",
     "code3": "LUX",
     "continent": "Europa",
     "number": 442
    },
    {
     "id": 145,
     "name": "Macau",
     "currency": "MOP",
     "phone": 853,
     "capital": "Macao",
     "code": "MO",
     "code3": "MAC",
     "continent": "Ásia",
     "number": 446
    },
    {
     "id": 146,
     "name": "Macedônia",
     "currency": "MKD",
     "phone": 389,
     "capital": "Skopje",
     "code": "MK",
     "code3": "MKD",
     "continent": "Europa",
     "number": 807
    },
    {
     "id": 147,
     "name": "Madagascar",
     "currency": "MGA",
     "phone": 261,
     "capital": "Antananarivo",
     "code": "MG",
     "code3": "MDG",
     "continent": "África",
     "number": 450
    },
    {
     "id": 148,
     "name": "Malásia",
     "currency": "MYR",
     "phone": 60,
     "capital": "Kuala Lumpur",
     "code": "MY",
     "code3": "MYS",
     "continent": "Ásia",
     "number": 458
    },
    {
     "id": 149,
     "name": "Malawi",
     "currency": "MWK",
     "phone": 265,
     "capital": "Lilongwe",
     "code": "MW",
     "code3": "MWI",
     "continent": "África",
     "number": 454
    },
    {
     "id": 150,
     "name": "Maldivas",
     "currency": "MVR",
     "phone": 960,
     "capital": "Male",
     "code": "MV",
     "code3": "MDV",
     "continent": "Ásia",
     "number": 462
    },
    {
     "id": 151,
     "name": "Mali",
     "currency": "XOF",
     "phone": 223,
     "capital": "Bamako",
     "code": "ML",
     "code3": "MLI",
     "continent": "África",
     "number": 466
    },
    {
     "id": 152,
     "name": "Malta",
     "currency": "EUR",
     "phone": 356,
     "capital": "Valletta",
     "code": "MT",
     "code3": "MLT",
     "continent": "Europa",
     "number": 470
    },
    {
     "id": 153,
     "name": "Marrocos",
     "currency": "MAD",
     "phone": 212,
     "capital": "Rabat",
     "code": "MA",
     "code3": "MAR",
     "continent": "África",
     "number": 504
    },
    {
     "id": 154,
     "name": "Martinica",
     "currency": "EUR",
     "phone": 596,
     "capital": "Fort-de-France",
     "code": "MQ",
     "code3": "MTQ",
     "continent": "América",
     "number": 474
    },
    {
     "id": 155,
     "name": "Maurício",
     "currency": "MUR",
     "phone": 230,
     "capital": "Port Louis",
     "code": "MU",
     "code3": "MUS",
     "continent": "África",
     "number": 480
    },
    {
     "id": 156,
     "name": "Mauritânia",
     "currency": "MRO",
     "phone": 222,
     "capital": "Nouakchott",
     "code": "MR",
     "code3": "MRT",
     "continent": "África",
     "number": 478
    },
    {
     "id": 157,
     "name": "Mayotte",
     "currency": "EUR",
     "phone": 269,
     "capital": "Mamoudzou",
     "code": "YT",
     "code3": "MYT",
     "continent": "África",
     "number": 175
    },
    {
     "id": 158,
     "name": "México",
     "currency": "MXN",
     "phone": 52,
     "capital": "Mexico City",
     "code": "MX",
     "code3": "MEX",
     "continent": "América",
     "number": 484
    },
    {
     "id": 159,
     "name": "Micronésia",
     "currency": "USD",
     "phone": 691,
     "capital": "Palikir",
     "code": "FM",
     "code3": "FSM",
     "continent": "Oceania",
     "number": 583
    },
    {
     "id": 160,
     "name": "Moçambique",
     "currency": "MZN",
     "phone": 258,
     "capital": "Maputo",
     "code": "MZ",
     "code3": "MOZ",
     "continent": "África",
     "number": 508
    },
    {
     "id": 161,
     "name": "Moldávia",
     "currency": "MDL",
     "phone": 373,
     "capital": "Chisinau",
     "code": "MD",
     "code3": "MDA",
     "continent": "Europa",
     "number": 498
    },
    {
     "id": 162,
     "name": "Mônaco",
     "currency": "EUR",
     "phone": 377,
     "capital": "Monaco",
     "code": "MC",
     "code3": "MCO",
     "continent": "Europa",
     "number": 492
    },
    {
     "id": 163,
     "name": "Mongólia",
     "currency": "MNT",
     "phone": 976,
     "capital": "Ulan Bator",
     "code": "MN",
     "code3": "MNG",
     "continent": "Ásia",
     "number": 496
    },
    {
     "id": 164,
     "name": "Montenegro",
     "currency": "EUR",
     "phone": 382,
     "capital": "Podgorica",
     "code": "ME",
     "code3": "MNE",
     "continent": "Europa",
     "number": 499
    },
    {
     "id": 165,
     "name": "Montserrat",
     "currency": "XCD",
     "phone": 1664,
     "capital": "Plymouth",
     "code": "MS",
     "code3": "MSR",
     "continent": "América",
     "number": 500
    },
    {
     "id": 166,
     "name": "Myanmar",
     "currency": "MMK",
     "phone": 95,
     "capital": "Nay Pyi Taw",
     "code": "MM",
     "code3": "MMR",
     "continent": "Ásia",
     "number": 104
    },
    {
     "id": 167,
     "name": "Namíbia",
     "currency": "NAD",
     "phone": 264,
     "capital": "Windhoek",
     "code": "NA",
     "code3": "NAM",
     "continent": "África",
     "number": 516
    },
    {
     "id": 168,
     "name": "Nauru",
     "currency": "AUD",
     "phone": 674,
     "capital": "Yaren",
     "code": "NR",
     "code3": "NRU",
     "continent": "Oceania",
     "number": 520
    },
    {
     "id": 169,
     "name": "Nepal",
     "currency": "NPR",
     "phone": 977,
     "capital": "Kathmandu",
     "code": "NP",
     "code3": "NPL",
     "continent": "Ásia",
     "number": 524
    },
    {
     "id": 170,
     "name": "Nicarágua",
     "currency": "NIO",
     "phone": 505,
     "capital": "Managua",
     "code": "NI",
     "code3": "NIC",
     "continent": "América",
     "number": 558
    },
    {
     "id": 171,
     "name": "Níger",
     "currency": "XOF",
     "phone": 227,
     "capital": "Niamey",
     "code": "NE",
     "code3": "NER",
     "continent": "África",
     "number": 562
    },
    {
     "id": 172,
     "name": "Nigéria",
     "currency": "NGN",
     "phone": 234,
     "capital": "Abuja",
     "code": "NG",
     "code3": "NGA",
     "continent": "África",
     "number": 566
    },
    {
     "id": 173,
     "name": "Niue",
     "currency": "NZD",
     "phone": 683,
     "capital": "Alofi",
     "code": "NU",
     "code3": "NIU",
     "continent": "Oceania",
     "number": 570
    },
    {
     "id": 174,
     "name": "Noruega",
     "currency": "NOK",
     "phone": 47,
     "capital": "Oslo",
     "code": "NO",
     "code3": "NOR",
     "continent": "Europa",
     "number": 578
    },
    {
     "id": 175,
     "name": "Nova Caledônia",
     "currency": "XPF",
     "phone": 687,
     "capital": "Noumea",
     "code": "NC",
     "code3": "NCL",
     "continent": "Oceania",
     "number": 540
    },
    {
     "id": 176,
     "name": "Nova Zelândia",
     "currency": "NZD",
     "phone": 64,
     "capital": "Wellington",
     "code": "NZ",
     "code3": "NZL",
     "continent": "Oceania",
     "number": 554
    },
    {
     "id": 177,
     "name": "Omã",
     "currency": "OMR",
     "phone": 968,
     "capital": "Muscat",
     "code": "OM",
     "code3": "OMN",
     "continent": "Ásia",
     "number": 512
    },
    {
     "id": 178,
     "name": "Palau",
     "currency": "USD",
     "phone": 680,
     "capital": "Melekeok",
     "code": "PW",
     "code3": "PLW",
     "continent": "Oceania",
     "number": 585
    },
    {
     "id": 179,
     "name": "Panamá",
     "currency": "PAB",
     "phone": 507,
     "capital": "Panama City",
     "code": "PA",
     "code3": "PAN",
     "continent": "América",
     "number": 591
    },
    {
     "id": 180,
     "name": "Papua-Nova Guiné",
     "currency": "PGK",
     "phone": 675,
     "capital": "Port Moresby",
     "code": "PG",
     "code3": "PNG",
     "continent": "Oceania",
     "number": 598
    },
    {
     "id": 181,
     "name": "Paquistão",
     "currency": "PKR",
     "phone": 92,
     "capital": "Islamabad",
     "code": "PK",
     "code3": "PAK",
     "continent": "Ásia",
     "number": 586
    },
    {
     "id": 182,
     "name": "Paraguai",
     "currency": "PYG",
     "phone": 595,
     "capital": "Asuncion",
     "code": "PY",
     "code3": "PRY",
     "continent": "América",
     "number": 600
    },
    {
     "id": 183,
     "name": "Peru",
     "currency": "PEN",
     "phone": 51,
     "capital": "Lima",
     "code": "PE",
     "code3": "PER",
     "continent": "América",
     "number": 604
    },
    {
     "id": 184,
     "name": "Polinésia Francesa",
     "currency": "XPF",
     "phone": 689,
     "capital": "Papeete",
     "code": "PF",
     "code3": "PYF",
     "continent": "Oceania",
     "number": 258
    },
    {
     "id": 185,
     "name": "Polônia",
     "currency": "PLN",
     "phone": 48,
     "capital": "Warsaw",
     "code": "PL",
     "code3": "POL",
     "continent": "Europa",
     "number": 616
    },
    {
     "id": 186,
     "name": "Porto Rico",
     "currency": "USD",
     "phone": 1787,
     "capital": "San Juan",
     "code": "PR",
     "code3": "PRI",
     "continent": "América",
     "number": 630
    },
    {
     "id": 187,
     "name": "Portugal",
     "currency": "EUR",
     "phone": 351,
     "capital": "Lisbon",
     "code": "PT",
     "code3": "PRT",
     "continent": "Europa",
     "number": 620
    },
    {
     "id": 188,
     "name": "Quênia",
     "currency": "KES",
     "phone": 254,
     "capital": "Nairobi",
     "code": "KE",
     "code3": "KEN",
     "continent": "África",
     "number": 404
    },
    {
     "id": 189,
     "name": "Quirguistão",
     "currency": "KGS",
     "phone": 996,
     "capital": "Bishkek",
     "code": "KG",
     "code3": "KGZ",
     "continent": "Ásia",
     "number": 417
    },
    {
     "id": 190,
     "name": "Reino Unido",
     "currency": "GBP",
     "phone": 44,
     "capital": "London",
     "code": "GB",
     "code3": "GBR",
     "continent": "Europa",
     "number": 826
    },
    {
     "id": 191,
     "name": "República Centro-Áfricana",
     "currency": "XAF",
     "phone": 236,
     "capital": "Bangui",
     "code": "CF",
     "code3": "CAF",
     "continent": "África",
     "number": 140
    },
    {
     "id": 192,
     "name": "República Democrática do Congo",
     "currency": "CDF",
     "phone": 242,
     "capital": "Kinshasa",
     "code": "CD",
     "code3": "COD",
     "continent": "África",
     "number": 180
    },
    {
     "id": 193,
     "name": "República Dominicana",
     "currency": "DOP",
     "phone": 1809,
     "capital": "Santo Domingo",
     "code": "DO",
     "code3": "DOM",
     "continent": "América",
     "number": 214
    },
    {
     "id": 194,
     "name": "República Tcheca",
     "currency": "CZK",
     "phone": 420,
     "capital": "Prague",
     "code": "CZ",
     "code3": "CZE",
     "continent": "Europa",
     "number": 203
    },
    {
     "id": 195,
     "name": "Romênia",
     "currency": "RON",
     "phone": 40,
     "capital": "Bucharest",
     "code": "RO",
     "code3": "ROM",
     "continent": "Europa",
     "number": 642
    },
    {
     "id": 196,
     "name": "Ruanda",
     "currency": "RWF",
     "phone": 250,
     "capital": "Kigali",
     "code": "RW",
     "code3": "RWA",
     "continent": "África",
     "number": 646
    },
    {
     "id": 197,
     "name": "Rússia",
     "currency": "RUB",
     "phone": 70,
     "capital": "Moscow",
     "code": "RU",
     "code3": "RUS",
     "continent": "Ásia",
     "number": 643
    },
    {
     "id": 198,
     "name": "Saara Ocidental",
     "currency": "MAD",
     "phone": 212,
     "capital": "El-Aaiun",
     "code": "EH",
     "code3": "ESH",
     "continent": "África",
     "number": 732
    },
    {
     "id": 199,
     "name": "Saba",
     "currency": "USD",
     "phone": 599,
     "capital": "Kralendijk",
     "code": "BQ",
     "code3": "BES",
     "continent": "América",
     "number": 535
    },
    {
     "id": 200,
     "name": "Samoa",
     "currency": "WST",
     "phone": 684,
     "capital": "Apia",
     "code": "WS",
     "code3": "WSM",
     "continent": "Oceania",
     "number": 882
    },
    {
     "id": 201,
     "name": "Samoa Americana",
     "currency": "USD",
     "phone": 1684,
     "capital": "Pago Pago",
     "code": "AS",
     "code3": "ASM",
     "continent": "Oceania",
     "number": 16
    },
    {
     "id": 202,
     "name": "San Marino",
     "currency": "EUR",
     "phone": 378,
     "capital": "San Marino",
     "code": "SM",
     "code3": "SMR",
     "continent": "Europa",
     "number": 674
    },
    {
     "id": 203,
     "name": "Santa Helena",
     "currency": "SHP",
     "phone": 290,
     "capital": "Jamestown",
     "code": "SH",
     "code3": "SHN",
     "continent": "África",
     "number": 654
    },
    {
     "id": 204,
     "name": "Santa Lúcia",
     "currency": "XCD",
     "phone": 1758,
     "capital": "Castries",
     "code": "LC",
     "code3": "LCA",
     "continent": "América",
     "number": 662
    },
    {
     "id": 205,
     "name": "São Bartolomeu",
     "currency": "EUR",
     "phone": 590,
     "capital": "Gustavia",
     "code": "BL",
     "code3": "BLM",
     "continent": "América",
     "number": 652
    },
    {
     "id": 206,
     "name": "São Cristóvão e Névis",
     "currency": "XCD",
     "phone": 1869,
     "capital": "Basseterre",
     "code": "KN",
     "code3": "KNA",
     "continent": "América",
     "number": 659
    },
    {
     "id": 207,
     "name": "São Martinho (Países Baixos)",
     "currency": "ANG",
     "phone": 1,
     "capital": "Philipsburg",
     "code": "SX",
     "code3": "SXM",
     "continent": "América",
     "number": 534
    },
    {
     "id": 208,
     "name": "São Pedro e Miquelão",
     "currency": "EUR",
     "phone": 508,
     "capital": "Saint-Pierre",
     "code": "PM",
     "code3": "SPM",
     "continent": "América",
     "number": 666
    },
    {
     "id": 209,
     "name": "São Tomé e Príncipe",
     "currency": "STD",
     "phone": 239,
     "capital": "Sao Tome",
     "code": "ST",
     "code3": "STP",
     "continent": "África",
     "number": 678
    },
    {
     "id": 210,
     "name": "São Vicente e Granadinas",
     "currency": "XCD",
     "phone": 1784,
     "capital": "Kingstown",
     "code": "VC",
     "code3": "VCT",
     "continent": "América",
     "number": 670
    },
    {
     "id": 211,
     "name": "Senegal",
     "currency": "XOF",
     "phone": 221,
     "capital": "Dakar",
     "code": "SN",
     "code3": "SEN",
     "continent": "África",
     "number": 686
    },
    {
     "id": 212,
     "name": "Serra Leoa",
     "currency": "SLL",
     "phone": 232,
     "capital": "Freetown",
     "code": "SL",
     "code3": "SLE",
     "continent": "África",
     "number": 694
    },
    {
     "id": 213,
     "name": "Sérvia",
     "currency": "RSD",
     "phone": 381,
     "capital": "Belgrade",
     "code": "RS",
     "code3": "SRB",
     "continent": "Europa",
     "number": 688
    },
    {
     "id": 215,
     "name": "Seychelles",
     "currency": "SCR",
     "phone": 248,
     "capital": "Victoria",
     "code": "SC",
     "code3": "SYC",
     "continent": "África",
     "number": 690
    },
    {
     "id": 216,
     "name": "Síria",
     "currency": "SYP",
     "phone": 963,
     "capital": "Damascus",
     "code": "SY",
     "code3": "SYR",
     "continent": "Ásia",
     "number": 760
    },
    {
     "id": 217,
     "name": "Somália",
     "currency": "SOS",
     "phone": 252,
     "capital": "Mogadishu",
     "code": "SO",
     "code3": "SOM",
     "continent": "África",
     "number": 706
    },
    {
     "id": 218,
     "name": "Sri Lanka",
     "currency": "LKR",
     "phone": 94,
     "capital": "Colombo",
     "code": "LK",
     "code3": "LKA",
     "continent": "Ásia",
     "number": 144
    },
    {
     "id": 219,
     "name": "Suazilândia",
     "currency": "SZL",
     "phone": 268,
     "capital": "Mbabane",
     "code": "SZ",
     "code3": "SWZ",
     "continent": "África",
     "number": 748
    },
    {
     "id": 220,
     "name": "Sudão",
     "currency": "SDG",
     "phone": 249,
     "capital": "Khartoum",
     "code": "SD",
     "code3": "SDN",
     "continent": "África",
     "number": 736
    },
    {
     "id": 221,
     "name": "Sudão do Sul",
     "currency": "SSP",
     "phone": 211,
     "capital": "Juba",
     "code": "SS",
     "code3": "SSD",
     "continent": "África",
     "number": 728
    },
    {
     "id": 222,
     "name": "Suécia",
     "currency": "SEK",
     "phone": 46,
     "capital": "Stockholm",
     "code": "SE",
     "code3": "SWE",
     "continent": "Europa",
     "number": 752
    },
    {
     "id": 223,
     "name": "Suíça",
     "currency": "CHF",
     "phone": 41,
     "capital": "Berne",
     "code": "CH",
     "code3": "CHE",
     "continent": "Europa",
     "number": 756
    },
    {
     "id": 224,
     "name": "Suriname",
     "currency": "SRD",
     "phone": 597,
     "capital": "Paramaribo",
     "code": "SR",
     "code3": "SUR",
     "continent": "América",
     "number": 740
    },
    {
     "id": 225,
     "name": "Svalbard e Jan Mayen",
     "currency": "NOK",
     "phone": 47,
     "capital": "Longyearbyen",
     "code": "SJ",
     "code3": "SJM",
     "continent": "Europa",
     "number": 744
    },
    {
     "id": 226,
     "name": "Tailândia",
     "currency": "THB",
     "phone": 66,
     "capital": "Bangkok",
     "code": "TH",
     "code3": "THA",
     "continent": "Ásia",
     "number": 764
    },
    {
     "id": 227,
     "name": "Taiwan",
     "currency": "TWD",
     "phone": 886,
     "capital": "Taipei",
     "code": "TW",
     "code3": "TWN",
     "continent": "Ásia",
     "number": 158
    },
    {
     "id": 228,
     "name": "Tajiquistão",
     "currency": "TJS",
     "phone": 992,
     "capital": "Dushanbe",
     "code": "TJ",
     "code3": "TJK",
     "continent": "Ásia",
     "number": 762
    },
    {
     "id": 229,
     "name": "Tanzânia",
     "currency": "TZS",
     "phone": 255,
     "capital": "Dodoma",
     "code": "TZ",
     "code3": "TZA",
     "continent": "África",
     "number": 834
    },
    {
     "id": 230,
     "name": "Terras Austrais e Antárticas Francesas",
     "currency": "EUR",
     "phone": 262,
     "capital": "Port-aux-Francais",
     "code": "TF",
     "code3": "ATF",
     "continent": "Antártida",
     "number": 260
    },
    {
     "id": 231,
     "name": "Território Britânico do Oceano Índico",
     "currency": "USD",
     "phone": 246,
     "capital": "Diego Garcia",
     "code": "IO",
     "code3": "IOT",
     "continent": "Ásia",
     "number": 86
    },
    {
     "id": 232,
     "name": "Territórios Palestinos Ocupados",
     "currency": "ILS",
     "phone": 970,
     "capital": "East Jerusalem",
     "code": "PS",
     "code3": "PSE",
     "continent": "Ásia",
     "number": 275
    },
    {
     "id": 233,
     "name": "Timor-Leste",
     "currency": "USD",
     "phone": 670,
     "capital": "Dili",
     "code": "TL",
     "code3": "TLS",
     "continent": "Ásia",
     "number": 626
    },
    {
     "id": 234,
     "name": "Togo",
     "currency": "XOF",
     "phone": 228,
     "capital": "Lome",
     "code": "TG",
     "code3": "TGO",
     "continent": "África",
     "number": 768
    },
    {
     "id": 235,
     "name": "Tonga",
     "currency": "TOP",
     "phone": 676,
     "capital": "Nuku'alofa",
     "code": "TO",
     "code3": "TON",
     "continent": "Oceania",
     "number": 776
    },
    {
     "id": 236,
     "name": "Toquelau",
     "currency": "NZD",
     "phone": 690,
     "capital": "Nukunonu",
     "code": "TK",
     "code3": "TKL",
     "continent": "Oceania",
     "number": 772
    },
    {
     "id": 237,
     "name": "Trindade e Tobago",
     "currency": "TTD",
     "phone": 1868,
     "capital": "Port of Spain",
     "code": "TT",
     "code3": "TTO",
     "continent": "América",
     "number": 780
    },
    {
     "id": 238,
     "name": "Tunísia",
     "currency": "TND",
     "phone": 216,
     "capital": "Tunis",
     "code": "TN",
     "code3": "TUN",
     "continent": "África",
     "number": 788
    },
    {
     "id": 239,
     "name": "Turcomenistão",
     "currency": "TMT",
     "phone": 7370,
     "capital": "Ashgabat",
     "code": "TM",
     "code3": "TKM",
     "continent": "Ásia",
     "number": 795
    },
    {
     "id": 240,
     "name": "Turquia",
     "currency": "TRY",
     "phone": 90,
     "capital": "Ankara",
     "code": "TR",
     "code3": "TUR",
     "continent": "Ásia",
     "number": 792
    },
    {
     "id": 241,
     "name": "Tuvalu",
     "currency": "AUD",
     "phone": 688,
     "capital": "Funafuti",
     "code": "TV",
     "code3": "TUV",
     "continent": "Oceania",
     "number": 798
    },
    {
     "id": 242,
     "name": "Ucrânia",
     "currency": "UAH",
     "phone": 380,
     "capital": "Kiev",
     "code": "UA",
     "code3": "UKR",
     "continent": "Europa",
     "number": 804
    },
    {
     "id": 243,
     "name": "Uganda",
     "currency": "UGX",
     "phone": 256,
     "capital": "Kampala",
     "code": "UG",
     "code3": "UGA",
     "continent": "África",
     "number": 800
    },
    {
     "id": 244,
     "name": "Uruguai",
     "currency": "UYU",
     "phone": 598,
     "capital": "Montevideo",
     "code": "UY",
     "code3": "URY",
     "continent": "América",
     "number": 858
    },
    {
     "id": 245,
     "name": "Uzbequistão",
     "currency": "UZS",
     "phone": 998,
     "capital": "Tashkent",
     "code": "UZ",
     "code3": "UZB",
     "continent": "Ásia",
     "number": 860
    },
    {
     "id": 246,
     "name": "Vanuatu",
     "currency": "VUV",
     "phone": 678,
     "capital": "Port Vila",
     "code": "VU",
     "code3": "VUT",
     "continent": "Oceania",
     "number": 548
    },
    {
     "id": 247,
     "name": "Vaticano",
     "currency": "EUR",
     "phone": 39,
     "capital": "Vaticano",
     "code": "VA",
     "code3": "VAT",
     "continent": "Europa",
     "number": 336
    },
    {
     "id": 248,
     "name": "Venezuela",
     "currency": "VEF",
     "phone": 58,
     "capital": "Caracas",
     "code": "VE",
     "code3": "VEN",
     "continent": "América",
     "number": 862
    },
    {
     "id": 249,
     "name": "Vietnã",
     "currency": "VND",
     "phone": 84,
     "capital": "Hanoi",
     "code": "VN",
     "code3": "VNM",
     "continent": "Ásia",
     "number": 704
    },
    {
     "id": 250,
     "name": "Wallis e Futuna",
     "currency": "XPF",
     "phone": 681,
     "capital": "Mata Utu",
     "code": "WF",
     "code3": "WLF",
     "continent": "Oceania",
     "number": 876
    },
    {
     "id": 251,
     "name": "Zâmbia",
     "currency": "ZMW",
     "phone": 260,
     "capital": "Lusaka",
     "code": "ZM",
     "code3": "ZMB",
     "continent": "África",
     "number": 894
    },
    {
     "id": 252,
     "name": "Zimbábue",
     "currency": "ZWL",
     "phone": 263,
     "capital": "Harare",
     "code": "ZW",
     "code3": "ZWE",
     "continent": "África",
     "number": 716
    }
 ];

 export const countryList = {
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas (the)",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia (Plurinational State of)",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory (the)",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands (the)",
    "CF": "Central African Republic (the)",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands (the)",
    "CO": "Colombia",
    "KM": "Comoros (the)",
    "CD": "Congo (the Democratic Republic of the)",
    "CG": "Congo (the)",
    "CK": "Cook Islands (the)",
    "CR": "Costa Rica",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "CI": "Côte d'Ivoire",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic (the)",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "SZ": "Eswatini",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (the) [Malvinas]",
    "FO": "Faroe Islands (the)",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories (the)",
    "GA": "Gabon",
    "GM": "Gambia (the)",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See (the)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea (the Democratic People's Republic of)",
    "KR": "Korea (the Republic of)",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic (the)",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands (the)",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia (Federated States of)",
    "MD": "Moldova (the Republic of)",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands (the)",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger (the)",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands (the)",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines (the)",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "MK": "Republic of North Macedonia",
    "RO": "Romania",
    "RU": "Russian Federation (the)",
    "RW": "Rwanda",
    "RE": "Réunion",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan (the)",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands (the)",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates (the)",
    "GB": "United Kingdom of Great Britain and Northern Ireland (the)",
    "UM": "United States Minor Outlying Islands (the)",
    "US": "United States of America (the)",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela (Bolivarian Republic of)",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Åland Islands"
};